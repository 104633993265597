<template>
  <div class="app">
    <div class="head">
      <img
        src="../../assets/allImage//presscenter/toutu_xinwenzhognxin@1x.png"
        alt=""
      />
    </div>
    <div class="List">
      <div
        class="obj_box"
        v-for="(item, index) in ArrrayList"
        :key="index"
        @click="getDetalis(item, index)"
      >
        <img
          class="srcImg"
          src="../../assets/allImage/presscenter/wenzitubiao@2x.png"
          alt=""
        />

        <div class="tow_Laout">
          <div class="title_sk">
            {{ item.title }}
          </div>
          <div class="content_sk">
            <div>{{ item.updateTime }}</div>
            <!-- <div class="vd_ds">查看详情></div> -->
          </div>
        </div>
      </div>
    </div>
    <noDataPrompt v-if="searchType" />
  </div>
</template>

<script>
import noDataPrompt from '.././noDataPrompt/index';
import { searchList } from '@/api/index';
export default {
  components: {
    noDataPrompt,
  },
  data() {
    return {
      title: '',
      searchType: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: '',
      },
      ArrrayList: [],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newTitle, oldTitle) {
        this.title = newTitle.title;
        this.getList(newTitle);
      },
    },
  },
  methods: {
    async getList(newTitle) {
      this.searchType = false;
      this.queryParams.title = newTitle.title;
      const res = await searchList(this.queryParams);
      if (res.data.code == 200) {
        if (res.data.data.records && res.data.data.records.length > 0) {
          this.ArrrayList = res.data.data.records;
        } else {
          this.searchType = true;
          this.ArrrayList = [];
        }
      }
    },
    async getDetalis(item) {
      // isExternalLink 1 外连接
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_black');
        return;
      }
      this.$router.push({
        path: '/layout/companyProfile/details',
        query: { title: this.title, id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  .List {
    width: 80%;
    margin: 0 auto;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 0.5rem;
    .obj_box {
      cursor: pointer;
      width: 100%;
      height: 2.1875rem;
      padding-left: 2.1875rem;
      margin-top: 25px;
      position: relative;
      border-bottom: 0.0625rem dashed black;
      .tow_Laout {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title_sk {
          text-align: left;
        }

        .content_sk {
          .vd_ds {
            color: #0aa4ee;
          }
        }
      }
      .srcImg {
        width: 1.25rem;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
